@import "../styles/mixins.scss";

.errorMessage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    :global {

        .inner {
            padding: 20px;
            width: 500px;
            background: var(--color-red);
            color: black;

            h1 {
                margin: 0;
                margin-bottom: 20px;
                padding: 0;
            }
        }
    }
}
