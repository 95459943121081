@import "../../../../styles/mixins.scss";

.columnMainWebsite {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: var(--color-stone);
    color: black;

    :global {
        .browser-bar {
            height: 80px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            margin-left: 1em;

            >* {
                background: no-repeat center;
                width: 50px;
                height: 50px;
                margin-right: 1em;
                border: none;
                appearance: none;

                &:disabled {
                    opacity: 0.2;
                }
            }

            .button-back {
                background-image: url(./ButtonBack.svg);
            }

            .button-foreward {
                background-image: url(./ButtonForeward.svg);
            }

            .button-refresh {
                background-image: url(./ButtonRefresh.svg);
            }
        }

        .adress-bar {
            $size: 30px;
            height: $size;
            background: white;
            border-radius: calc($size / 2);
            align-items: center;
            justify-content: center;
            padding: 0 15px;
            max-width: 400px;

            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 100%;
                line-height: $size;
            }
        }

        .window {
            background: white;
            position: relative;
            flex-grow: 1;
            
            .webview {
                position: absolute;
                width: 100%;
                height: 100%;
                border: none;
            }
            
            .blocked-url {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                $padding: 100px;
                position: absolute;
                left: $padding;
                top: $padding;
                right: $padding;
                bottom: $padding;
                padding: 50px;
                background: var(--color-berry);
            }
    
    
        }
    }
}
