@import "../../styles/mixins.scss";

.menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    touch-action: pan-x;
    scrollbar-width: none;
    overflow-x: scroll;
    overflow-y: hidden;

    display: flex;
    flex-direction: row;
    overscroll-behavior-x: none;

    background: white;
    color: black;

    perspective: 100px;

    * {
        transform-style: preserve-3d;
    }

    >section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 5em;
        box-sizing: border-box;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    :global {

        #category-start {
            .intro {
                text-align: right;
            }

        }

        a {
            color: black;
        }

        .intro {
            min-width: 200px;
            max-width: 580px;
            position: relative;

            p {
                margin-bottom: 0;
            }

            .arrow-button {
                display: block;
                width: 52px;
                height: 52px;
                background: url(../../styles/img/ArrowButton.svg) no-repeat center;
                cursor: pointer;
                position: absolute;
                bottom: 0;
                left: 100%;
                margin-left: 1em;
            }
        }

        .separator {
            min-width: 80px;
            height: 100%;
            background: url(../../styles/img/Separator.svg) no-repeat center;
            transform: translateZ(-10px) scale(1.1);
        }

        .root-nav {
            .category-link {
                @include font-headline(3em);
                padding: 0px 30px 8px 30px;
                color: var(--color-text);
                background: var(--color-link);
                border: 1px solid var(--color-border);
                white-space: nowrap;
                display: inline-block;
                margin: 10px 0;
                cursor: pointer;                
            }
            
            .submenu-index-0 { margin-left: 150px; }
            .submenu-index-1 { margin-left: 200px; }
            .submenu-index-2 { margin-left: 80px; }
            .submenu-index-3 { margin-left: 0px; }
            .submenu-index-4 { margin-left: 120px; }
        }

        @include color-scheme();

        .submenu {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: 0 0 0 150px;

            .separator {
                margin: 0 150px 0 0;
            }

            &.submenu-level-1 {
                >.inner {
                    min-width: 600px;

                    h1 {
                        @include font-headline(4em);
                        margin-top: 70px
                    }


                    section {
                        text-align: left;
                    }
                }

                >.children {
                    display: flex;
                    flex-direction: row;
                }

                &:last-child {
                    margin-right: 500px;
                }
            }

            &.submenu-level-2 {
                flex-direction: column;

                >.inner {
                    h1 {
                        max-width: 500px;
                        letter-spacing: 0.05em;
                        min-height: 100px;
                        margin: 80px 0;
                    }
                }

                >.children {
                    display: grid;
                    grid-auto-flow: column;
                    grid-template-rows: repeat(2, auto);

                    gap: 20px 200px;
                }
            }

            a {
                color: var(--color-text);
                background-color: var(--color-link);
                border: 2px solid var(--color-border);
                display: flex;
                flex-direction: row;
                height: 300px;
                width: 600px;

                >* {
                    width: 300px;
                    height: 100%;
                    display: inline-flex;
                    letter-spacing: 0.05em;
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    padding: 0.5em 0.9em;
                    overflow: hidden;
                    height: 100%;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 50px;
                        bottom: -2px;

                        background: linear-gradient(transparent 0%, var(--color-link) 100%);
                    }

                }

                .excerpt-wrap {
                    display: flex;

                    p {
                        font-size: 1.1em;
                        overflow: hidden;
                        margin-bottom: 0;
                        overflow-wrap: anywhere;
                    }

                }

                .title-wrap {
                    @include font-headline(1.5em);

                    div {
                        white-space: normal;
                        overflow-wrap: anywhere;
                    }
                }

                .img-placeholder {
                    background: var(--color-link);
                }

                img {
                    object-fit: cover;
                    background: white;
                }
            }

            &.submenu-level-2 {
                h1 {
                    color: var(--color-headline);
                }

            }
        }

        .imprint-link {
            @include font-headline(1.5em);
            text-align: center;
            a {
                color: var(--color-berry) !important;
            }
        }

    }

}
