@use "sass:math";

@mixin img($file, $size: 100px) {
    background-image: url('./img/#{$file}');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: $size;
    width: $size;
    display: inline-block;
    border: 0;
}

@mixin font-headline($size: 2em) {
    font-family: var(--font-headline);
    text-transform: lowercase;
    font-weight: normal;
    font-size: $size;
    $space: math.div($size, 30);
    letter-spacing: $space;
    line-height: 0.9;
}

@mixin color-scheme() {
    
    .submenu-index-0 { --color-link: var(--color-berry); --color-headline: var(--color-berry); --color-text: white; --color-border: transparent; }
    .submenu-index-1 { --color-link: var(--color-history); --color-headline: var(--color-history); --color-text: white; --color-border: transparent; }
    .submenu-index-2 { --color-link: var(--color-mocca); --color-headline: var(--color-mocca); --color-text: white; --color-border: transparent; }
    .submenu-index-3 { --color-link: var(--color-green); --color-headline: var(--color-green); --color-text: white; --color-border: transparent; }
    .submenu-index-4 { --color-link: white; --color-headline: black; --color-text: black; --color-border: black; }
    .submenu-map { --color-link: var(--color-history); --color-headline: var(--color-history); --color-text: white; --color-border: transparent; }

}
