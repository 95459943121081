@font-face {
    font-family: "Roboto";
    font-weight: normal;
    font-style: normal;
    src: url(Roboto-Regular.ttf);
}

@font-face {
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    src: url(Roboto-Bold.ttf);
}

@font-face {
    font-family: "Roboto";
    font-weight: normal;
    font-style: italic;
    src: url(Roboto-Italic.ttf);
}

@font-face {
    font-family: "Roboto";
    font-weight: bold;
    font-style: italic;
    src: url(Roboto-BoldItalic.ttf);
}

@font-face {
    font-family: "Neutralizer";
    font-weight: normal;
    font-style: normal;
    src: url(Neutralizer-Caps-Bold.ttf);
}
