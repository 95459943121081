
.layout {

    :global {

        .container {
            display: flex;
            width: 100vw;
            height: 100vh;
            justify-content: center;
            align-items: center;

            > .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                
                width: 1920px;
                height: 1080px;
                justify-self: center;
                align-self: center;
                overflow: hidden;
            }
        }


    }

}
