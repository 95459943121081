@import "../../styles/mixins.scss";

.map {
    width: 100%;
    height: 100%;

    :global {

        .marker {
            font-family: Roboto;
            font-size: 12px;
            position: absolute;
            z-index: 10;
            $size: 80px;

            &.open {
                z-index: 11;
            }

            .enlarged {
                font-size: 16px;
            }

            svg {
                cursor: pointer;
                z-index: 101;
                position: absolute;
                left: -32px;
                top: -85px;
                width: 64px;
                height: 85px;
                filter: drop-shadow(0px 10px 4px rgba(0, 0, 0, 0.25));
            }

            .icon {
                cursor: pointer;
                z-index: 101;
                position: absolute;
                background-repeat: no-repeat;
                background-size: contain;
                left: -27px;
                top: -83px;
                width: 54px;
                height: 75px;
            }

            .close {
                @include img("../../styles/img/CloseButton.svg", 30px);
                cursor: pointer;
                z-index: 101;
                position: absolute;
                right: 20px;
            }

            .info {
                position: absolute;
                top: $size * -0.2;
                left: -300px;
                width: 600px;
                z-index: 100;
                background: white;
                padding: 20px 40px;

                font-size: 1.2em;
                line-height: 1.5;

                a {
                    color: var(--color-red);
                    cursor: pointer;
                }

                .skip-button {
                    display: block;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 50%;
                    margin-top: -15px;
                    &.prev {
                        left: 5px;
                        background: url(./images/arrow-prev.svg) no-repeat center center;
                    }
                    &.next {
                        right: 5px;
                        background: url(./images/arrow-next.svg) no-repeat center center;
                    }
                
                }

            }

        }
    }

}

.categories {
    color: white;
    background: var(--color-berry);
    display: flex;
    padding: 0 0 0 20px;

    flex-direction: column;
    align-items: flex-end;

    :global {
        h2 {
            flex-grow: 1;
            margin-top: 17px;
            margin-left: 10px;
        }

        .column-side {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            margin-right: 0;
            
        }

        .category {
            font-size: 140%;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 10px 10px 12px;
            margin-bottom: 20px;
            width: 100%;
            cursor: pointer;
            background: rgba(#E66998, 0.8);
            
            &.active {
                background: transparent;
                font-weight: bold;
            }
            .patch {
                display: inline-block;
                position: relative;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
    }
}
