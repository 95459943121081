@import "../../styles/mixins.scss";

.navBar {
    width: 100%;
    height: 70px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: white;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);

    >* {
        display: flex;
        flex-direction: row;
    }

    :global {

        @include color-scheme();


        .category-link {

            @include font-headline(32px);
            color: var(--color-stone);
            display: flex;
            align-items: center;
            padding: 0 1em;
            cursor: pointer;
            span {
                margin-top: -3px;
            }

            &.active {
                color: var(--color-text);
                background: var(--color-link);

            }

            &.category-start {
                &:before {
                    content: "";
                    @include img("../../styles/img/IconHome.svg", 25px);
                    position: relative;
                    padding-right: 12px;
                    top: 0;
                }
            }

            &.category-map {
                &:before {
                    content: "";
                    @include img("../../styles/img/IconMap.svg", 30px);
                    position: relative;
                    padding-right: 20px;
                    top: 0;
                    color: red;
                }

                &.active:before {
                    @include img("../../styles/img/IconMapWhite.svg", 30px);
                }
            }
        }

        .toggle-lang,
        .toggle-size {
            display: inline-flex;
            border-radius: 50%;
            background-color: var(--color-stone);
            width: 40px;
            height: 40px;
            align-self: center;
            margin-left: 30px;
            @include font-headline(16px);
            color: white;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            span {
                margin-top: -3px;
            }
        }

        .toggle-size {
            span {
                @include img("../../styles/img/IconFontSize.svg", 25px);
                background-position-y: bottom !important;
                margin-top: -8px;
            }
            
            &.active {
                span {
                    @include img("../../styles/img/IconFontSizeReset.svg", 25px);
                    background-position-y: bottom !important;
                }
            }
        }
    }
}
