:root {
    --color-dark-blue: #0C2433;
    --color-blue: #0D455C;
    --color-gray: #898989;
    --color-berry: #C60955;
    --color-stone: #4E393D;
    --color-mocca: #ABA89A;
    --color-green: #B2C18F;
    --color-red: #FF300F;
    --color-history: #455C50;

    --font-headline: "Neutralizer";
    --font-copy: "Roboto";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-headline;
}

body {
    overscroll-behavior: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    font-family: var(--font-copy);
    background: black;
    user-select: none;
}

p {
    font-size: 1.2em;
    line-height: 140%;
    letter-spacing: 0.04em;
}

html,
body {
    padding: 0;
    margin: 0;
}

a {
    color: white;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

code {
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: 'Courier New', Courier, monospace;
    background: rgba(255, 255, 255, 0.2);
}


body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
}

body ::-webkit-scrollbar-track {
    background: transparent;
}

body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 8px;
    background: #B7BEC2;
    transition: color 0.2s ease;
}

body ::-webkit-scrollbar-thumb:window-inactive {
    background: #B7BEC2;
}

body ::-webkit-scrollbar-thumb:hover {
    background: white;
}

main {
    background: white;
}

.qr-wrap {
    text-align: center;
    background: white;
    color: black;
}

.qr-code {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 30px;
    //background: white;

    svg {
        shape-rendering: crispEdges;
    }
}
