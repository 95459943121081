.closeButton {
    display: block;
    width: 52px;
    height: 52px;
    position: absolute;
    right: 12px;
    top: 12px;
    background: url(../styles/img/CloseButton.svg) no-repeat center;
    cursor: pointer;
}
